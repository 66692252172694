<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-breakpoint="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <v-divider class="mb-1" />

    <v-list
      dense
      nav
    >
      <v-list-item>
        <v-list-item-avatar
          class="align-self-center"
          color="white"
          contain
        >
          <v-img
            src="/Lets_Connect_icon.png"
            max-height="30"
          />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            class="display-1"
            v-text="profile.title"
          />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list
      expand
      nav
    >
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        >
          <!--  -->
        </base-item-group>

        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>

      <div class="bottomActions">
        <router-link :to="{ path: `/pages/knowledgebase` }">
          <div
            class="knowledgeBase"
          >
            Knowledge Base
          </div>
        </router-link>
        <button
          class="logoutBtn"
          @click="logout"
        >
          Logout
        </button>
      </div>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import {
    mapState,
  } from 'vuex'

  import AppAddOnUtility from '../../../../utils/app-addon-utility'

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      isGoogleReviewEnabled: false,
      items: [
        {
          icon: 'mdi-nutrition',
          title: 'Dashboard',
          to: '/pages/dashboard',
        },
        {
          icon: 'mdi-account',
          title: 'My Senders',
          to: '/pages/senders',
        },
        {
          title: 'My Contacts',
          icon: 'mdi-clipboard-outline',
          to: '/pages/contacts',
        },
        {
          title: 'My Groups',
          icon: 'mdi-google-circles',
          to: '/pages/groups',
        },
        {
          title: 'Message Insights',
          icon: 'mdi-chart-pie',
          to: '/pages/insights',
        }, {
          title: 'Survey Insights',
          icon: 'mdi-chart-bar',
          to: '/pages/survey-insights',
        },
        {
          title: 'Manage Templates',
          icon: 'mdi-file-document',
          to: '/pages/manage-templates',
        },
        {
          title: 'Schduled SMS',
          icon: 'mdi-file-document',
          to: '/pages/scheduled-sms',
        },
      ],
    }),

    computed: {
      ...mapState(['barColor', 'barImage', 'appAddOns']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },
      profile () {
        return {
          avatar: true,
          title: this.$t('avatar'),
        }
      },
    },

    watch: {
      barColor (val) {
        console.log('barColor :>> ', val)
      },
      appAddOns (val) {
        this._setPermissionsFromAddons(val)
      },
    },

    created () {
      this._setPermissionsFromAddons(this.$store.state.appAddOns)
      AppAddOnUtility.initialize()
    },

    methods: {
      _setPermissionsFromAddons (val) {
        if (val && val.length) {
          const googleReview = val.find(addOn => addOn.AppAddOnID === 6)
          if (googleReview.isEnabled) {
            const googleReviewIndex = this.items.findIndex(i => i.icon === 'mdi-google')
            if (googleReviewIndex === -1) {
              this.items.push({
                title: 'Google Reviews',
                icon: 'mdi-google',
                to: '/pages/google-review',
              })
            }
          } else {
            const googleReviewIndex = this.items.findIndex(i => i.icon === 'mdi-google')
            if (googleReviewIndex > -1) {
              this.items.splice(googleReviewIndex, 1)
            }
          }
        }
      },
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },

      logout () {
        if (confirm('Are you sure you want to logout?')) {
          localStorage.clear()
          window.location = window.location.origin + '/home'
        }
      },

    },
  }
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'
  .bottomActions
    position: absolute
    bottom: 20px
    width: 90%
    right: 6%
    .knowledgeBase
      padding: 25px 20px
      text-decoration: underline
      cursor: pointer
      text-align: center
    .logoutBtn
      border: 1px solid grey
      border-radius: 3px
      background-color: transparent
      width: 100%
      height: 40px
      display: flex
      justify-content: center
      align-items: center

  #core-navigation-drawer
    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px

</style>
